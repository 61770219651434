@import 'tailwindcss/base';

@font-face {
  font-family: 'Mona Sans';
  font-weight: 200 900;
  font-display: block;
  font-style: normal;
  font-stretch: 75% 125%;
  src: url('../fonts/Mona-Sans.var.woff2') format('woff2');
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcf6ef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9d8681;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9d8681;
}
.background-recepsion {
  background-image: url('../images/recepsion.jpg') ; /* Use the relative path to the image */
 height: 30rem;
  background-position: center;
}

html {
  --scroll-behavior: smooth!important;
  scroll-behavior: smooth!important;
  }